:root {
  scroll-behavior: smooth;
  font-size: 62.5%;  /* sets 1rem === 10px */
}

html, body {
  height: 100%;
  color: #DADDDF;
  background: #000000;
  margin: 0;
  font-family: 'VT323', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #DADDDF;
}